<!--
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->

<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
    <!-- <p v-if="showInstructions" class="notification-instructions">
      To receive notifications, please enable them in your browser settings.
      <button @click="openNotificationSettings">Open Notification Settings</button>
    </p> -->
  <!-- <sidenav v-if="showSidenav" /> -->
  <main class="main-content position-relative max-height-vh-100 h-100">
    <!-- <navbar-admin v-if="showNavbarAdmin" :showTopSearch="showTopSearch" />
    <navbar-front v-if="showNavbarFront" :showTopSearch="showTopSearch" /> -->
    <!-- <Suspense> -->
      <!-- <div>Support Lib: {{ support_lib_id }}</div> -->
      <router-view />
    <!-- </Suspense> -->
    <!-- <app-footer v-show="showFooter" /> -->
    <!-- <configurator
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    /> -->
  </main>
</template>
<script>
/* import Sidenav from "./examples/Sidenav"; */
/* import Configurator from "@/examples/Configurator.vue"; */
/* import NavbarAdmin from "@/components/custom/admin/NavbarAdmin.vue";
import NavbarFront from "@/components/custom/front/NavbarFront.vue"; */
import AppFooter from "@/components/custom/Footer.vue";
import { mapState } from "vuex";

/* import { ref } from 'vue'
import getUser from "@/composables/getUser"
import getUserInfo from "@/composables/getUserInfo"
import getSupportLibrary from "@/composables/getSupportLibrary" */

export default {
  name: "App",
  components: {
    /* Sidenav, */
    /* Configurator, */
    /* NavbarAdmin,
    NavbarFront, */
    AppFooter
  },
  data() {
    return {
      showInstructions: false
    };
  },
  mounted() {
    this.checkNotificationPermission();
  },
  methods: {
    checkNotificationPermission() {
      if (Notification.permission === 'denied') {
        this.showInstructions = true;
      }
    },
    openNotificationSettings() {
      // Inform the user how to enable notifications based on their browser
      alert('Please enable notifications in your browser settings. Here’s how:\n\n' +
        '1. In Chrome, click the lock icon next to the URL and select "Allow" under Notifications.\n' +
        '2. In Firefox, click the shield icon next to the URL and select "Allow" under Notifications.\n' +
        '3. In Safari, go to Preferences > Websites > Notifications and enable it for this site.\n');
    }
  },
  setup(){
    /* const support_lib_id = ref('');
    console.log("Setting Up");
    const { user } = getUser();
    const { user_info_error, user_info, load_user_info } = getUserInfo(user.value.uid)
    console.log("Setting Up 2");
    await load_user_info();
    console.log("Setting Up 3");

    if(user_info.value.support_lib_id){
      support_lib_id.value = user_info.value.support_lib_id;
    }
    console.log("Setting Up 4");
    console.log("In App.vue support lib ID: " + support_lib_id.value);
    const { support_lib_error, user_support_library_details, load_support_library  } = getSupportLibrary('n6YtGUlxahUwbpvzImG7')    
    await load_support_library();
    console.log("Support Lib Name: " + user_support_library_details.value.library_domain);
    return { user, user_info_error, user_info, support_lib_error, user_support_library_details, support_lib_id } */
  },
  computed: {
    ...mapState([
      "layout",
      "showSidenav",
      /* "showNavbarAdmin",
      "showNavbarFront", */
      "showFooter",
      "showTopSearch",
      "showConfig",
      "hideConfigButton"
    ])
  }
};
</script>
<style>
.notification-instructions {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 15px;
  border-radius: 5px;
}
</style>